import * as React from "react";
import Dialog from "@mui/material/Dialog";
import VerifyEmailIcon from "../../icons/VerifyEmailIcon";
import { DialogContent, Stack, Typography, alpha } from "@mui/material";
import {
  useAuth,
  user,
  setEmailVerificationFlag,
} from "../../hooks/store/useAuth";
import { socket } from "../../socket";
import useApi from "../../hooks/useApi";
import { useSnack } from "../../hooks/store/useSnack";
import useThemeStore from "../../hooks/store/useThemeStore";
// import { useSocket } from "../../hooks/store/useSocket";
import ButtonWithLoading from "../ui/button/ButtonWithLoading";
import { useTheme } from "@emotion/react";

export default function EmailVerifyDialog({
  open = false,
  setOpen,
  verifyUserIdentity,
}) {
  const { userId: loggedInUserId, setEmailVerificationFlag, user } = useAuth();
  const { apiCall: API, isLoading: apiLoading } = useApi();
  const { apiCall, isLoading: loading } = useApi(true);
  const { setSnack } = useSnack();
  const [timer, setTimer] = React.useState(59);
  const [isResendDisabled, setIsResendDisabled] = React.useState(true);
  const { darkMode } = useThemeStore();
  // const { socketConnection } = useSocket();
  const { palette } = useTheme();
  // this function fire on email verification done
  const emailVerified = React.useCallback(
    async (value) => {
      try {
        if (value.email_verified.email) {
          setEmailVerificationFlag(true);
          setOpen(false);
          setEmailVerificationFlag(value.email_verified.email);
          socket.on("disconnect", () => {});
          verifyUserIdentity();
          return setSnack("Your email has been verified");
        }
      } catch (error) {}
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // effect for connect socket
  // React.useEffect(() => {
  //   socket.connect();
  //   return () => {
  //     socket.disconnect();
  //   };
  // }, []);

  //  socket for email verification
  React.useEffect(() => {
    // const data = { userId: loggedInUserId };
    socket.on("connect", () => {});
    socket.on("evntUpdateAccountCheck", emailVerified);
    // socket.emit("evntJoinUserToRoom", data);
    socket.on("connect_error", (err) => {
      console.log(`connect_error due to ${err.message}`);
    });

    return () => {
      socket.off("connect", () => {});
      socket.off("disconnect", () => {});
    };
  }, [emailVerified, loggedInUserId]);

  // code for resend otp
  const ResendEmail = async (msg = true) => {
    try {
      setIsResendDisabled(true);
      await API({
        url: `v3/users/${loggedInUserId}/verify-email`,
        method: "post",
      });
      setTimer(59);
      msg &&
        setSnack(
          "We've successfully resent the verification link to your email address. Please check your email.",
          "success"
        );
    } catch (error) {
      if (
        error.response.data.code === 400 &&
        error.response.data.success === false
      ) {
        setEmailVerificationFlag(true);
        setOpen(false);
        socket.on("disconnect", () => {});
        verifyUserIdentity();
        return setSnack("Your email has been verified");
      }
      setIsResendDisabled(true);
      setTimer(0);
      setSnack(error.response.data.message);
    }
  };

  // code for check is email verified or not
  const checkEmailVerification = async () => {
    let response = await apiCall({
      url: "v2/accounts/check-verified-2",
      method: "post",
      data: { userId: loggedInUserId },
    });
    let emailVerification = response.data.data.email_verified.email;
    if (emailVerification) {
      setEmailVerificationFlag(true);
      setOpen(false);
      verifyUserIdentity();
      return setSnack("Your email has been verified");
    } else {
      return setSnack("Please verify your email", "warning");
    }
  };

  React.useEffect(() => {
    ResendEmail(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Start the countdown timer when the component mounts
  React.useEffect(() => {
    const interval = setInterval(() => {
      if (timer > 0) {
        setTimer(timer - 1);
      } else {
        setIsResendDisabled(false); // Enable the "Resend OTP" button when the timer reaches 0
        clearInterval(interval); // Stop the timer
      }
    }, 1000);

    return () => {
      clearInterval(interval); // Clean up the interval when the component unmounts
    };
  }, [timer]);
  return (
    <React.Fragment>
      {window.location.pathname !== "/email-verification" && (
        <Dialog
          maxWidth={"md"}
          open={open}
          sx={{
            "& .MuiPaper-root": {
              mx: "15px",
              borderRadius: "15px!important",
              boxShadow: "none",
              border: "none",
              backgroundColor: "unset",
            },
            "& .MuiDialog-container": {
              background: alpha(palette.black[1000], 0.6),
              backdropFilter: "blur(2px)",
            },
          }}
        >
          <DialogContent
            sx={{
              padding: { xs: "15px", md: "40px" },
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: darkMode ? "bgDark.third" : "bgWhite.main",
            }}
          >
            <VerifyEmailIcon
              sx={{
                width: { xs: "215px", sm: "328px", md: "444px" },
                height: { xs: "180px", sm: "230px", md: "296px" },
              }}
            />
            <Typography
              sx={{
                mt: { xs: "10px", md: "20px" },
                color: darkMode ? "white.1000" : "black.1000",
                textAlign: "center",
                fontFamily: "Poppins",
                fontSize: { xs: "16px", md: "22px" },
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "normal",
              }}
            >
              Verify Your Email Address
            </Typography>
            <Typography
              sx={{
                mx: "auto",
                width: { lg: "674px" },
                color: darkMode ? "grey.450" : "black.1000",
                textAlign: "center",
                fontFamily: "Poppins",
                fontSize: { xs: "12px", md: "18px" },
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: { xs: "22px", md: "normal" },
                mt: { xs: "10px", md: "15px" },
              }}
            >
              We've sent a verification link to your email address:{" "}
              <Typography
                component={"span"}
                sx={{
                  color: darkMode ? "white.1000" : "primary.main",
                  fontSize: { xs: "12px", md: "18px" },
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: { xs: "22px", md: "normal" },
                }}
              >
                {user.email}
              </Typography>
              . Please check your email and click on the verification link to
              confirm your email. Once verified, you'll be able to log in to
              your account.
            </Typography>
            <Stack
              sx={{
                width: "100%",
                mt: "25px",
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                alignItems: "center",
                justifyContent: "center",
                gap: { xs: "12px", sm: "12px" },
              }}
            >
              <ButtonWithLoading
                isLoading={apiLoading}
                disabled={isResendDisabled}
                variant="contained"
                onClick={ResendEmail}
                sx={{
                  padding: "12px 30px",
                  minWidth: { xs: "100%", sm: "148.58px" },
                  width: { xs: "100%", sm: "unset" },
                  "&.Mui-disabled": {
                    color: darkMode ? "black.100" : "white.1000",
                    backgroundColor: darkMode
                      ? "primary.disabledDark"
                      : "primary.disabledLight",
                  },
                }}
              >
                Resend
              </ButtonWithLoading>
            </Stack>
            <Typography
              sx={{
                mt: 1,
                fontSize: { xs: "12px", md: "16px" },
                color: darkMode ? "white.1000" : "primary.main",
              }}
            >
              00:{timer < 10 ? `0${timer}` : timer}
            </Typography>

            {/* {!socketConnection && ( */}
            <ButtonWithLoading
              isLoading={loading}
              variant="text"
              onClick={checkEmailVerification}
              sx={{
                padding: "12px 30px",
                width: { xs: "100%", sm: "unset" },
                color: darkMode ? "white.1000" : "primary.main",
                "&.Mui-disabled": {
                  color: darkMode ? "black.100" : "white.1000",
                  backgroundColor: "none",
                },
              }}
            >
              Done verification? continue
            </ButtonWithLoading>
            {/* )} */}
          </DialogContent>
        </Dialog>
      )}
    </React.Fragment>
  );
}
