import {
  Box,
  Typography,
  Link as MuiLink,
  Button,
  alpha,
  Stack,
} from "@mui/material";
import React, { useState } from "react";
import Input from "../ui/form/Input";
import UserIcon from "../../icons/UserIcon";
import PasswordInput from "../ui/form/PasswordInput";
import Shield from "../../icons/Shield";
import ButtonWithLoading from "../ui/button/ButtonWithLoading";
import GoogleIcon from "../../icons/GoogleIcon";
import AppleIcon from "../../icons/AppleIcon";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useTheme } from "@emotion/react";
import useThemeStore from "../../hooks/store/useThemeStore";
import { useFeed } from "../../hooks/store/useFeed";
import { useAuth } from "../../hooks/store/useAuth";
import useApi from "../../hooks/useApi";
import { useSnack } from "../../hooks/store/useSnack";
import { useSignin } from "../../hooks/store/useSignin";
import { signInWithGoogle, signInWithApple } from "../../services/firebase";
import PersonIcon from "../../icons/PersonIcon";
import EmailVerifyDialog from "./EmailVerifyDialog";

const SignInForm = ({ openNextForm }) => {
  const { darkMode } = useThemeStore();
  const { setSnack } = useSnack();
  const { apiCall } = useApi(true);
  const { apiCall: API } = useApi();
  const { typography, palette } = useTheme();
  const { resetFeedStore } = useFeed();
  const { login } = useAuth();
  const navigate = useNavigate();
  const { setUserId, setNormalLogin, setStep1, setFlag, setIdToken, setEmail } =
    useSignin();
  let [wrongPasswordError, setWrongPasswordError] = useState("");
  let [wrongUsername, setWrongUsername] = useState("");
  let [appleLoading, setAppleLoading] = useState(false);
  let [googleLoading, setGoogleLoading] = useState(false);
  let [openEmailVerify, setOpenEmailVerify] = useState(false);

  // form handling
  const schema = Yup.object({
    username: Yup.string().required("username/Email is required.").min(6),
    password: Yup.string().required("password is required.").min(8).max(32),
  });
  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const signInUsingGoogle = async () => {
    try {
      setGoogleLoading(true);
      const result = await signInWithGoogle();
      setIdToken(result);
      let user = await apiCall({
        url: "/v2/login/social-login",
        method: "POST",
        data: {
          idToken: result,
        },
      });

      if (user.status === 200) {
        // console.log(res.data.data);
        let {
          userId,
          firstName,
          lastName,
          username,
          orgName,
          email,
          accessToken,
          refreshToken,
          profileImage,
          mobile,
          code,
          email_verified,
          mobile_verified,
          isKyc,
          onboarding_complete,
        } = user.data.data;
        login({
          user: {
            firstName,
            lastName,
            username,
            orgName,
            email,
            profileImage,
            mobile,
            code,
            email_verified,
            mobile_verified,
            isKyc,
            onboarding_complete,
          },
          refreshToken,
          accessToken,
          userId,
        });
        setSnack("login successfully");
        resetFeedStore();
        setGoogleLoading(false);
        navigate("/");
      }
    } catch (error) {
      setGoogleLoading(false);
      let mobile_required = error.response.data?.data.mobile_required;
      let mobile_varified = error.response.data?.data.mobile_verified;
      let onboarding = error.response.data?.data.onboarding;
      let userId = error?.response?.data?.data?.userId;

      if (mobile_required === undefined && error.response.data.code === 401) {
        return setSnack(error.response.data.message, "warning");
      }

      if (
        mobile_required === false &&
        mobile_varified === true &&
        onboarding === false
      ) {
        setSnack("Complete your On-Boarding process.", "warning");
        setUserId(userId);
        openNextForm("Onboarding");
        return;
      } else {
        if (error.response.data.data)
          if (error.response.data.code === 401) {
            setSnack(
              "For security reason mobile number is required",
              "warning"
            );
            openNextForm("AddMobileNumber");
            return;
          }
      }
    }
  };

  const signInUsingApple = async () => {
    try {
      setAppleLoading(true);
      const result = await signInWithApple();
      setIdToken(result);
      let user = await apiCall({
        url: "/v2/login/social-login",
        method: "POST",
        data: {
          idToken: result,
        },
      });

      if (user.status === 200) {
        // console.log(res.data.data);
        let {
          userId,
          firstName,
          lastName,
          username,
          orgName,
          email,
          accessToken,
          refreshToken,
          profileImage,
          mobile,
          code,
          email_verified,
          mobile_verified,
          isKyc,
          onboarding_complete,
        } = user.data.data;
        login({
          user: {
            firstName,
            lastName,
            username,
            orgName,
            email,
            profileImage,
            mobile,
            code,
            email_verified,
            mobile_verified,
            isKyc,
            onboarding_complete,
          },
          refreshToken,
          accessToken,
          userId,
        });
        // console.log(user.data.data);
        setAppleLoading(false);
        setSnack("login successful.");
        resetFeedStore();
        navigate("/");
      }
    } catch (error) {
      setAppleLoading(false);
      console.log(error);
      let mobile_required = error.response?.data.data.mobile_required;
      let mobile_varified = error.response?.data.data.mobile_varified;
      let onboarding = error.response.data?.data.onboarding;
      let userId = error?.response?.data?.data?.userId;

      if (mobile_required === undefined && error.response.data.code === 401) {
        return setSnack(error.response.data.message, "warning");
      }

      if (
        mobile_required === false &&
        mobile_varified === true &&
        onboarding === false
      ) {
        setSnack("Complete your On-Boarding process.", "warning");
        setUserId(userId);
        openNextForm("Onboarding");
        return;
      }
      if (error.response.data.data)
        if (error.response.data.code === 401) {
          setSnack("For security reason mobile number is required", "warning");
          openNextForm("AddMobileNumber");
          return;
        }
    }
  };
  const signInHandler = async (values) => {
    try {
      // clean old backedn error
      setWrongPasswordError("");
      setWrongUsername("");

      let username = values.username;
      let password = values.password;
      setStep1(username, password);
      const res = await apiCall({
        method: "post",
        url: "v2/login/signin-v2",
        data: {
          username: username,
          password: password,
          role: "customer",
        },
      });
      if (res.status === 200) {
        // console.log(res.data.data);
        let {
          _id: userId,
          firstName,
          lastName,
          username,
          orgName,
          email,
          accessToken,
          refreshToken,
          profileImage,
          mobile,
          code,
          email_verified,
          mobile_verified,
          isKyc,
          onboarding_complete,
        } = res.data.data;
        login({
          user: {
            firstName,
            lastName,
            username,
            orgName,
            email,
            profileImage,
            mobile,
            code,
            email_verified,
            mobile_verified,
            isKyc,
            onboarding_complete,
          },
          refreshToken,
          accessToken,
          userId,
        });
        setSnack("login successfully");
        resetFeedStore();
        navigate("/");
      }
    } catch (error) {
      let errorMessage = error.response.data.message;
      // code for manage username and password related error
      setNormalLogin(true);

      if (error.response?.data?.data?.email) {
        setEmail(error.response?.data?.data?.email);
      }

      if (
        error.response.status === 401 &&
        error.response?.data?.data?.mobile_required &&
        error.response?.data?.data?.mobile_verified === false
      ) {
        // code for old user without mobile varification
        setUserId(error.response?.data?.data?.userId);
        setSnack(errorMessage, "warning");
        return openNextForm("AddMobileNumber");
      }

      // if onboarding is pending
      // if (
      //   error.response.status === 401 &&
      //   error.response?.data?.data?.email_verified === true &&
      //   error.response?.data?.data?.onboarding === false
      // ) {
      //   setUserId(error.response?.data?.data?.userId);
      //   setSnack(errorMessage, "warning");
      //   return openNextForm("Onboarding");
      // }

      // code for invalid password
      if (
        error.response.status === 401 &&
        error.response?.data?.data?.onboarding === undefined
      ) {
        setWrongPasswordError("Invalid password");
        return setSnack(errorMessage, "error");
      }

      // code for uername not found
      if (error.response.status === 404) {
        setWrongUsername("User ID/Email not found.");
        return setSnack(errorMessage, "error");
      }

      // code for old user mobile varification
      let userId = error.response.data.data.userId;
      let mobile_required = error.response.data.data.mobile_required;
      let mobile_varified = error.response.data.data.mobile_verified;
      let onboarding = error.response.data.data.onboarding;
      let email_verified = error.response.data.data.email_verified;
      // code for manage old user
      setUserId(userId);
      setFlag(mobile_required, mobile_varified, onboarding);
      // setFlag(mobile_required, mobile_varified, onboarding, email_verified);
      setSnack(errorMessage, "warning");
      if (mobile_required) {
        return openNextForm("AddMobileNumber");
      }

      if (mobile_required === false && mobile_varified !== true) {
        // request for otp varification
        try {
          await apiCall({
            url: "v2/accounts/get-otp",
            method: "post",
            data: {
              code: "1",
              mobile: values.mobileNumber,
              OTP_description: "registration",
            },
          });
          return openNextForm("Varify");
        } catch (error) {
          setSnack(error.response.data.message, "error");
        }
      }

      // if (
      //   !email_verified &&
      //   error.response.data.data.email_verified !== undefined
      // ) {
      //   setOpenEmailVerify(true);
      //   // send mail
      //   try {
      //     await API({
      //       url: `v3/users/${userId}/verify-email`,
      //       method: "post",
      //     });
      //   } catch (error) {}
      // }
      return 0;
    }
  };

  return (
    <>
      <Box
        component={"form"}
        sx={{
          minWidth: { md: "571px" },
          paddingInline: { xs: "20px", md: "30px", lg: "60px" },
          paddingTop: { xs: "20px", md: "40px", lg: "50px" },
          paddingBottom: { xs: "175px", md: "40px", lg: "50px" },
          backgroundColor: darkMode ? "bgDark.third" : "white.1000",
          borderRadius: { xs: "10px", lg: "18px" },
          marginTop: { xs: 0, lg: "50px" },
          boxShadow: "0px 0px 100px rgba(0, 0, 0, 0.06)",
        }}
        onSubmit={handleSubmit(signInHandler)}
      >
        <Box sx={{ maxWidth: "431px", margin: "0 auto" }}>
          {/* form headings */}
          <Typography
            sx={{
              fontFamily: typography.manrope,
              color: darkMode ? "white.1000" : "black.1000",
              fontSize: { xs: "26px", lg: "36px" },
              fontWeight: { xs: 800, lg: 700 },
              lineHeight: { xs: "40px", lg: "122%" },
              letterSpacing: { xs: "-0.52px", md: "-0.72px" },
            }}
          >
            Hey! 👋
            <br /> Welcome to{" "}
            <Typography
              sx={{
                color: "transparent",
                background: palette.gradients["main"],
                backgroundClip: "text",
                fontSize: { xs: "26px", lg: "36px" },
                fontWeight: { xs: 800, lg: 700 },
                lineHeight: { xs: "40px", lg: "122%" },
                letterSpacing: { xs: "-0.52px", md: "-0.72px" },
                fontFamily: typography.manrope,
              }}
              component={"span"}
            >
              PloomSocial
            </Typography>
          </Typography>
          <Typography
            sx={{
              mt: "10px",
              fontFamily: typography.manrope,
              color: darkMode ? "grey.900" : "black.350",
              fontSize: { xs: "18px", lg: "16px" },
              fontWeight: 400,
              lineHeight: "152%",
            }}
          >
            Enter your User name and Password.
          </Typography>

          <Box sx={{ paddingTop: { lg: "10px" } }}>
            <Input
              name={"username"}
              inputLabel={"User ID/Email"}
              type="text"
              register={register}
              errors={formState.errors}
              placeholder="Enter User ID/Email"
              icon={
                <UserIcon
                  color={
                    darkMode ? palette.white["1000"] : palette.black["450"]
                  }
                />
              }
              serverError={wrongUsername}
            />

            <PasswordInput
              name="password"
              inputLabel={"Password"}
              register={register}
              errors={formState.errors}
              placeholder="Enter Your Password"
              icon={
                <Shield
                  color={
                    darkMode ? palette.white["1000"] : palette.black["450"]
                  }
                />
              }
              serverError={wrongPasswordError}
            />

            <Stack sx={{ display: "flex", alignItems: "end" }}>
              <Button
                component={Link}
                to={"/forgot-password"}
                variant="text"
                sx={{
                  paddingInline: 0,
                  display: "inline-block",
                  maxWidth: "150px",
                  color: darkMode ? "white.1000" : "primary.main",
                  fontFamily: "Manrope",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "146%",
                }}
              >
                Forgot Password?
              </Button>
            </Stack>
          </Box>

          <ButtonWithLoading
            color="primary"
            disabled={!formState.isValid || formState.isSubmitting}
            isLoading={formState.isSubmitting}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            sx={{
              fontFamily: typography.manrope,
              fontSize: "16px",
              fontWeight: 500,
              lineHeight: "152%",
              padding: "14px 12px",
              marginTop: { xs: "25px", lg: "30px" },
              borderRadius: "10px",
              textTransform: "capitalize",
              "&.Mui-disabled": {
                color: darkMode
                  ? alpha(palette.white["1000"], 0.7)
                  : "white.1000",
                backgroundColor: darkMode
                  ? "primary.disabledDark"
                  : "primary.disabledLight",
              },
            }}
          >
            Sign In
          </ButtonWithLoading>
        </Box>

        {/* oauth and other form links */}
        <Box
          sx={{
            marginTop: { xs: "30px", lg: "40px" },
            display: "flex",
            justifyContent: "center",
          }}
        >
          <MuiLink
            component={Link}
            to="/signup"
            underline="hover"
            sx={{
              fontFamily: typography.manrope,
              color: darkMode ? "grey.900" : "white.200",
              fontSize: { xs: "14px", md: "16px" },
              fontWeight: "500",
              lineHeight: "146%",
            }}
          >
            {"Don’t have an account?  "}
            <Typography
              component={"span"}
              sx={{
                color: darkMode ? "white.1000" : "primary.main",
                fontFamily: typography.manrope,
                fontSize: { xs: "14px", md: "16px" },
                fontWeight: "700",
              }}
            >
              {" Sign Up"}
            </Typography>
          </MuiLink>
        </Box>

        <Box display={"flex"} justifyContent={"center"}>
          <ButtonWithLoading
            // disabled
            isLoading={googleLoading}
            startIcon={<GoogleIcon></GoogleIcon>}
            fullWidth
            onClick={signInUsingGoogle}
            variant="contained"
            sx={{
              "&.MuiButtonBase-root": { textTransform: "unset" },
              mt: "30px",
              color: darkMode ? "white.1000" : "black.100",
              fontSize: "13px",
              paddingBlock: "13px",
              borderRadius: "10px",
              backgroundColor: darkMode ? "grey.550" : "white.100",
              fontWeight: "600",
              maxWidth: "431px",
              gap: "10px",
              textTransform: "unset",
              "&:hover": {
                color: "white.1000",
              },
              "&.Mui-disabled": {
                color: darkMode
                  ? alpha(palette.white["1000"], 0.7)
                  : "white.1000",
                backgroundColor: darkMode
                  ? "primary.disabledDark"
                  : "primary.disabledLight",
              },
            }}
          >
            Sign in with Google
          </ButtonWithLoading>
        </Box>

        <Box display={"flex"} justifyContent={"center"}>
          <ButtonWithLoading
            startIcon={
              <AppleIcon
                color={darkMode ? palette.white["1000"] : palette.black["1000"]}
              ></AppleIcon>
            }
            isLoading={appleLoading}
            fullWidth
            variant="contained"
            onClick={signInUsingApple}
            sx={{
              "&.MuiButtonBase-root": { textTransform: "unset" },
              mt: "10px",
              color: darkMode ? "white.1000" : "black.100",
              fontSize: "13px",
              paddingBlock: "13px",
              borderRadius: "10px",
              backgroundColor: darkMode ? "grey.550" : "white.100",
              fontWeight: "600",
              textTransform: "capitalize",
              maxWidth: "431px",
              gap: "10px",
              "&:hover": {
                color: "white.1000",
              },
              "&:hover .appleIcon": {
                fill: "#ffffff!important",
              },
              "&.Mui-disabled": {
                color: darkMode
                  ? alpha(palette.white["1000"], 0.7)
                  : "white.1000",
                backgroundColor: darkMode
                  ? "primary.disabledDark"
                  : "primary.disabledLight",
              },
            }}
          >
            Sign in with Apple
          </ButtonWithLoading>
        </Box>

        <Box display={"flex"} justifyContent={"center"}>
          <Button
            component={Link}
            to="/"
            startIcon={
              <PersonIcon
                color={darkMode ? palette.white["1000"] : palette.black["1000"]}
              ></PersonIcon>
            }
            fullWidth
            variant="contained"
            sx={{
              "&.MuiButtonBase-root": { textTransform: "unset" },
              mt: "10px",
              color: darkMode ? "white.1000" : "black.100",
              fontSize: "13px",
              paddingBlock: "13px",
              borderRadius: "10px",
              backgroundColor: darkMode ? "grey.550" : "white.100",
              fontWeight: "600",
              textTransform: "capitalize",
              maxWidth: "431px",
              gap: "10px",
              "&:hover": {
                color: "white.1000",
              },
              "&:hover .personIcon": {
                fill: "#ffffff!important",
              },
              "&.Mui-disabled": {
                color: darkMode
                  ? alpha(palette.white["1000"], 0.7)
                  : "white.1000",
                backgroundColor: darkMode
                  ? "primary.disabledDark"
                  : "primary.disabledLight",
              },
            }}
          >
            Continue as a Guest
          </Button>
        </Box>

        {openEmailVerify && (
          <EmailVerifyDialog
            open={openEmailVerify}
            setOpen={setOpenEmailVerify}
            openNextForm={openNextForm}
          />
        )}
      </Box>
    </>
  );
};

export default SignInForm;
